body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #1E1E1E;
    box-sizing: border-box;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}


h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

button,
input {
    border: none;
    outline: none;
    background: transparent;
}

ul,
li {
    list-style: none;
}

.hidden {
    display: none;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background:#1E1E1E;
    border: 1px solid #2D2D2D;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2D2D2D;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #2D2D2D;
}