.Footer{
    width: 100%;
    background-color: #2D2D2D;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    box-shadow: 0px 0px 15px 0px #000;
    z-index: 2000;
}

.Footer p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
}

.Footer p span {
    color: red;
}