.Header{
    width: 100%;
    background-color: #2D2D2D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12vh;
    box-shadow: 0px 0px 15px 0px #000;
    z-index: 999;
}

.Header__leftContainer,
.Header__rightContainer{
    display: flex;
    padding: 15px 40px;
}