.modalBtn {
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    width: 441px;
    height: 293px;
}

.container form {
    display: flex;
    flex-direction: column;
}

.container h3 {
    text-align: center;
    margin-bottom: 20px;
}

.container form span {
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}

.container input {
    margin-bottom: 20px;
    padding: 10px;
    background: transparent;
    color: #fff;
    border-bottom: 1px solid #000;
    font-size: 15px;
}

.submitBtn {
    background: #fff;
    color: #000;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    margin: 10px 0;
}

.submitBtn:hover {
    background:rgba(255, 255, 255, 0.5);
}

.Login .container p{
    color: #fff;
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
}

.Login .container p button {
    color: red;
    cursor: pointer;
}