.Modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 500;
    background-color:#2D2D2D;
    border-radius: 5px;
    min-width: 40%;
	min-height: 200px;
}

.PostModal {
    min-width: 100%;
	min-height: 100%;
    width: 100%;
    height: 100vh;
    background-color: #000;
    grid-template-columns: 1fr 350px;
}
  
  
@media only screen and (max-width: 900px) {
    .PostModal {
        grid-template-columns: 100% !important;  
    }
}


