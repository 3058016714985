.ImageUpload{
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalBtn,
.uploadedImageContainer .removeBtn {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 5px;
}

.modalBtn img,
.uploadedImageContainer .removeBtn img{
    width: 32px;
    height: 32px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    width: 444px;
    height: 450px;
    justify-content: center;
}

.container form {
    display: flex;
    flex-direction: column;
}

.container h3 {
    text-align: center;
    margin-bottom: 20px;
}

.container form span {
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}

.dragdrop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dragdrop .uploadBtn {
    width: 100%;
    background: transparent;
    height: 100px;
    color: #fff;
    border: 4px dotted #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.dragdrop .uploadBtn img {
   width: 50px;
   height: 50px;
}

.uploadedImageContainer {
    text-align: center;
    width: fit-content;
    margin-top: 20px;
    position: relative;
}

.uploadedImageContainer .removeBtn{
    position: absolute;
    background: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    padding: 3px;
    top: -10px;
    right: -10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.uploadedImageContainer .removeBtn:hover{
    background: rgba(255, 255, 255, 0.5);
}

.uploadedImageContainer .removeBtn img{
    width: 15px;
    height: 15px;
    vertical-align: bottom;
}


.container textarea {
    margin: 20px 0;
    border-radius: 5px;
    padding: 10px;
    background: transparent;
    color: #fff;
    border: none;
    border: 1px solid #000;
    outline: none;
    height: 75px;
    resize: none;
}

.submitBtn {
    background: #fff;
    color: #000;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
}

.submitBtn:hover {
    background:rgba(255, 255, 255, 0.5);
}

.ImageUpload .container p{
    color: #fff;
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
}

.ImageUpload .container p button {
    color: red;
    cursor: pointer;
}